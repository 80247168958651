<template>
<!--    <a href="okzuji://pages_order/orderList/index" style="color: #FF3333;">scheme</a>-->
    <a href="okzuji://orderList" style="color: #FF3333;">scheme</a>
</template>

<script>
export default {
  name: 'Home',

  methods: {

  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: .64rem;
  text-align: center;
}
ul {
  li {
    padding: .5333rem;
    color: green;
  }
}
</style>
